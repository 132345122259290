var exports = {};

exports = (url, opts) => {
  if (typeof url !== "string") {
    throw new TypeError(`Expected \`url\` to be of type \`string\`, got \`${typeof url}\``);
  }

  url = url.trim();
  opts = Object.assign({
    https: false
  }, opts);

  if (/^\.*\/|^(?!localhost)\w+:/.test(url)) {
    return url;
  }

  return url.replace(/^(?!(?:\w+:)?\/\/)/, opts.https ? "https://" : "http://");
};

export default exports;